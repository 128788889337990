import React from "react";
import Layout from "../layouts/layout.js";
import SEO from "../components/Seo";
import tw from "twin.macro";
import Header from "../components/Header.js";
import { H1 } from "../utils/helpers.js";

const PageWrapper = tw.section`
  px-4 md:px-8 my-8 max-w-3xl mx-auto
`;

export default function Home() {
  return (
    <Layout>
      <SEO title="Privacy Policy" />

      <Header bgImage="https://source.unsplash.com/pCVi2aDTQaM/1600x900">
        <H1>404 - Page not found</H1>
      </Header>

      <PageWrapper></PageWrapper>
    </Layout>
  );
}
